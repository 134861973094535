import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { audioOn, streams, mediaState } from 'src/app/k-video/k-video.selectors';
import { KVideoService } from 'src/app/k-video/k-video.service';

@Component({
  selector: 'sound-mono',
  templateUrl: './mono.component.html',
  styleUrls: ['./mono.component.scss']
})
export class MonoComponent implements OnInit {

  _role: string = "me";

  @Input() set role(r: string) {
    if (this._role != r) {
      this._role = r
    }
  }


  bNoSound: boolean = false
  count_no_sound: number = 0
  _stream: MediaStream | undefined

  remote_audio: boolean = true

  @Input() set audio_stream(s: MediaStream | undefined) {
    this._stream = s
    if (this._stream) {
      this.setupOsc()
    } else {
      this.stopOsc()
    }
  };


  num_audio_tracks: number = 0
  track: any
  bAudioOn: boolean = false
  interval: any
  bars: any[]
  orig_bars: any[] = [{
    height: 14,
    top: 8
  },
  {
    height: 24,
    top: 3
  },
  {
    height: 14,
    top: 8
  },
  ]
  no_sound_bars: any[] = [{
    height: 6,
    top: 12
  },
  {
    height: 6,
    top: 12
  },
  {
    height: 6,
    top: 12
  },
  ]
  subscriptions: any[] = []
  constructor(
    private kVideoState: Store<KVideoState>,
    private video_service: KVideoService
  ) {
    console.log("snd bar")
    this.bars = Object.assign([], this.no_sound_bars)
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async ngOnInit() {

  }

  ngAfterViewInit() {
    this.subscriptions.push(this.kVideoState.select(mediaState).subscribe((media_state: any) => {
      let new_media_state = media_state[this._role]
      if (new_media_state) {
        this.remote_audio = new_media_state.audio
      }
    }))
    if (!this.track) {
      setTimeout(() => {
        this.setupOsc()
      }, 1)

    }
  }
  stopOsc() {
    console.log("#CASU_" + this._role + " stopOsc ")
    if (this.interval) {
      clearInterval(this.interval)
      delete this.interval
    }
    this.bNoSound = true;
    this.count_no_sound = 0
    // console.log("#CASU_" + this._role + " stopOsc bNoSound " + this.bNoSound)

  }
  setupOsc() {
    let me = this
    console.log("#CASU_" + this._role + " setupOsc ")
    this.bNoSound = false
    this.count_no_sound = 0
    if (!this._stream) {
      this.bNoSound = true
      this.count_no_sound = 0
      // console.log("#CASU_" + this._role + " no stream bNoSound " + this.bNoSound)
      return
    }


    const audioCtx = new AudioContext();
    const analyser = audioCtx.createAnalyser();
    analyser.fftSize = 2048;
    const bufferLength = analyser.frequencyBinCount;
    const dataArray = new Uint8Array(bufferLength);
    let WIDTH = 3
    let HEIGHT = 100
    // let canvasCtx = this.canvas.nativeElement.getContext("2d");
    // canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
    const barWidth = Math.floor(bufferLength / WIDTH)// 341 = 1024/3 bufferLength/ WIDTH;
    let prev_num_audio_tracks = 0

    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this._stream) {

          let at = this._stream.getAudioTracks()
          if (at.length > 0) {
            if (prev_num_audio_tracks == 0) {
              prev_num_audio_tracks = at.length
              const source = audioCtx.createMediaStreamSource(this._stream);
              source.connect(analyser);
            }
            if (this.bNoSound != !at[0].enabled) {
              this.bNoSound = !at[0].enabled
              // console.log("#CASU_" + this._role + " bNoSound =!at[0].enabled   " + me.bNoSound)
            }
            analyser.getByteTimeDomainData(dataArray);
            let value_index = 0;
            let vdif = 0;
            let acumulator: number = 0
            let acumulator_index = 0;
            for (let i = 0; i < bufferLength; i++) {
              // const v = dataArray[i] / 128.0;
              const v = (dataArray[i] / 128.0) - 1;
              vdif += v
              const y = v * (HEIGHT / 2);
              if (v > 0) {
                acumulator += v
              } else {
                acumulator += v * -1
              }
              if (me.bNoSound == false) {

                if (++acumulator_index == barWidth) {
                  acumulator_index = 0;
                  let value = acumulator / barWidth //From 0 to 1
                  let amplified_val = value * 20// max_value
                  let h = me.orig_bars[value_index].height * amplified_val
                  if (h > me.orig_bars[value_index].height) {
                    h = me.orig_bars[value_index].height
                  }
                  if (h < 10) {
                    h = 4
                  }
                  let dif = me.orig_bars[value_index].height - h
                  let t = me.orig_bars[value_index].top + dif / 2
                  let entry = { height: h, top: t }
                  let bars = Object.assign(me.bars)
                  bars[value_index] = entry
                  if (value_index == 1 && h > 4) {
                    this.video_service.detectedSound(this._role)
                  }
                  me.bars = bars;
                  // }
                  if (++value_index >= WIDTH) {
                    value_index = 0
                  }
                  acumulator = 0;
                }
              }
            }
            if (Math.abs(vdif) < 0.50) {
              if (me.bNoSound == false) {
                if (++me.count_no_sound > 20) { //2 secs
                  me.bNoSound = true
                  this.count_no_sound = 0
                }
                // console.log("#CASU_" + this._role + " (vdif) < 0.50 bNoSound" + this.bNoSound)

                this.bars = Object.assign([], this.no_sound_bars)
              }
            } else {
              if (me.bNoSound) {
                me.bNoSound = false
              }
            }
          } else {
            prev_num_audio_tracks = 0
            if (me.bNoSound == false) {
              me.bNoSound = true
              // console.log("#CASU_" + this._role + " at.length ==0 bNoSound" + this.bNoSound)
            }
          }
        }
      }, 100)
    }

  }
  clickDbg() {
  }

}
