import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { fomatTime } from 'src/app/utils';
import { NotifyError, NotifyUser, PlayRec } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { hostUrl, myRoll } from '../../pages.selectors';
import { PagesService } from '../../pages.service';
import { Clipboard } from '@angular/cdk/clipboard'
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ChatService } from 'src/app/chat/chat.service';
@Component({
  selector: 'call-history',
  templateUrl: './history.component.html',
  styleUrls: ['../../contacts/leads/leads.component.scss', './history.component.scss']
})
export class HistoryComponent implements OnInit {
  calls: any[] = []
  // interval: any
  count: number = 0
  in_page: number = 1
  page_size: number = 10
  num_pages: number = 10
  detail_opts = ['View Chat', 'Call Recording', 'Transcript']
  @ViewChild('content') private content: ElementRef | undefined;

  sel_call_index: number = -1
  options_y: number = 53;
  options: any[] = []
  show_chat_id: string | undefined

  sel_info: any
  search: string = ""
  filter_types: string[] = ["Personal", "Company"]
  sel_index: number = 1;
  title: string = "Company"
  roll: string = 'admin'
  host_url: string | undefined
  subscriptions: any[] = []
  transcript: any[] | undefined
  names: any = { rep: "rep", caller: "caller" }
  constructor(
    private pages_service: PagesService,
    private pagesState: Store<PagesState>,
    private clipboard: Clipboard,
    public canvasStore: Store<CanvasState>,
    private chat_serivce: ChatService,
  ) {
    console.log("Call History")
    this.setDuration()
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(myRoll).subscribe((my_roll: string) => {
      setTimeout(() => this.roll = my_roll);
    }))
  }


  ngAfterViewInit() {
    this.getCalls()
  }

  getDate(call: any) {
    return new Date(call.start)
  }
  getDuration(call: any) {
    if (call.end) {
      let s = Math.round((call.end - call.start) / 1000)
      let m = Math.floor(s / 60)
      let h = Math.floor(m / 60)
      m -= h * 60
      s -= (h * 60 * 60 + m * 60)
      let rv = ""
      if (h > 0) {
        if (h < 10) {
          rv += "0" + h
        } else {
          rv + h
        }
        rv += ":"
      }
      if (m < 10) {
        rv += "0" + m
      } else {
        rv + m
      }
      rv += ":"
      if (s < 10) {
        rv += "0" + s
      } else {
        rv += s
      }
      return rv;
    }
    //      hh:mm:ss
    return "   n/a  "
  }

  async  getCalls() {
    try {
      let page_size = 10
      if (this.content) {
        let h = this.content.nativeElement.clientHeight
        if (h) {
          page_size = Math.floor(h / 60);
          setTimeout(() => {
            this.page_size = page_size
          }, 1)
        }
      }

      let rv = await this.pages_service.getHistory(this.in_page, page_size, this.sel_index, this.search)
      this.calls = rv.calls
      this.count = rv.count
      this.num_pages = Math.ceil(this.count / page_size)
    } catch (e) {
      console.error(e)
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    // clearInterval(this.interval)
  }
  setDuration() {
    // let now = new Date().getTime()
    let calls: any[] = Object.assign([], this.calls)
    for (let i = 0; i < calls.length; i++) {
      let call: any = Object.assign({}, calls[i])
      call.duration = fomatTime(call.end_at - call.start_at)
      calls[i] = call
    }
    this.calls = calls
  }
  ngOnInit(): void {
  }


  openDetailsFor($event: any, index: number) {
    if (this.sel_call_index == index) {
      this.sel_call_index = -1
    } else {
      setTimeout(() => {
        this.options_y = 53 + index * 60
        let call = this.calls[index]

        let opts = []
        if (call.chat_id) {
          opts.push({ name: 'View Chat', type: 'chat', call: call })
        }
        if (call.rec_num > 0) {
          if (call.rec_num == 1) {
            opts.push({ name: 'Call Recording', type: 'play', call: call, index: 1 })
          } else {
            for (let i = 0; i < call.rec_num; i++) {
              let n = i + 1
              let name = 'Call Recording ' + n
              opts.push({ name: name, type: 'play', call: call, index: i + 1 })
            }
          }
        }

        this.options = opts
        if (this.options.length > 0) {
          this.sel_call_index = index;
        } else {
          this.sel_call_index = -1
        }
      })
    }
  }
  hasDetails(call: any) {
    if (call.chat_id) {
      return true;
    }
    if (call.rec_num > 0) {
      return true;
    }
    return false;
  }
  show(opt: any) {
    let call = opt.call
    this.show_chat_id = call.chat_id
    this.sel_call_index = -1
  }
  async play(call: any) {


    if (call) {
      if (call._id && this.host_url) {
        let protocol: string = "https://"
        if (this.host_url.startsWith("local")) {
          protocol = location.protocol + "//"
        }
        let url = protocol + this.host_url + "?rec_id=" + call._id

        window.open(url);

      }

    }


  }

  filterType($event: any) {
    for (let i = 0; i < $event.length; i++) {
      let entry = $event[i]
      if (entry.selected) {
        this.title = entry.desc
        this.sel_index = i
        break;
      }
    }
    this.getCalls()
  }
  async callLink(call: any) {
    try {
      let url

      if (call._id && this.host_url) {
        let protocol: string = "https://"
        if (this.host_url.startsWith("local")) {
          protocol = location.protocol + "//"
        }
        url = protocol + this.host_url + "?rec_id=" + call._id

        this.clipboard.copy(url);
        this.pagesState.dispatch(new NotifyUser("Recorder link copied"))
      }
    } catch (e) {
      console.error(e)
    }
  }
  selPage($event: any) {
    this.in_page = $event
    this.getCalls()
  }
  changedSearch() { //filter by name
    this.getCalls()
  }
  showCallChat(call: any) {
    this.chat_serivce.setViewCall(call)
    this.canvasStore.dispatch(new SelectToolOpts("view_chat"))
  }
  async showTranscript(call: any) {
    try {
      if (call.caller_name) {
        this.names.caller = call.caller_name
      }
      if (call.rep_name) {
        this.names.caller = call.rep_name
      }
      this.transcript = await this.pages_service.getTranscript(call.call_id)
      console.log(JSON.stringify(this.transcript))
    } catch (e) {
      this.pagesState.dispatch(new NotifyError("No transcript availabvle"))
    }
  }
  close() {
    this.transcript = undefined
  }
}
