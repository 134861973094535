import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PagesState } from "./pages.model";

export const selectPagesStateState = createFeatureSelector<PagesState>('kpages');



//******************************************************************************
//
//******************************************************************************
export const gotAssets = createSelector(
  selectPagesStateState,
  state => state.assets
)
export const gotAssetsAndContracts = createSelector(
  selectPagesStateState,
  state => state.assets_and_contracts
)

export const isPhone = createSelector(
  selectPagesStateState,
  state => state.is_phone
)
//Rep Selectors

export const acceptedEULA = createSelector(
  selectPagesStateState,
  state => state.accepted_eula_on
)

export const myPicture = createSelector(
  selectPagesStateState,
  state => state.my_picture
)

export const myEmail = createSelector(
  selectPagesStateState,
  state => state.my_email
)
export const myName = createSelector(
  selectPagesStateState,
  state => state.my_name
)

export const repEmail = createSelector(
  selectPagesStateState,
  state => state.rep_email
)
export const repName = createSelector(
  selectPagesStateState,
  state => state.rep_name
)
export const myLastName = createSelector(
  selectPagesStateState,
  state => state.my_last_name
)
export const myCompany = createSelector(
  selectPagesStateState,
  state => state.my_company
)
export const myCompanyTitle = createSelector(
  selectPagesStateState,
  state => state.my_company_title
)
export const mySms = createSelector(
  selectPagesStateState,
  state => state.my_sms
)
export const myCompanyName = createSelector(
  selectPagesStateState,
  state => state.my_company_name
)
export const workTime = createSelector(
  selectPagesStateState,
  state => state.work_time
)
export const gotQueue = createSelector(
  selectPagesStateState,
  state => state.queue
)
export const repUrl = createSelector(
  selectPagesStateState,
  state => state.rep_url
)
export const hostUrl = createSelector(
  selectPagesStateState,
  state => state.host_url
)
export const callWaiting = createSelector(
  selectPagesStateState,
  state => state.call_waiting
)
export const callRejected = createSelector(
  selectPagesStateState,
  state => state.call_rejected
)
export const callerFirstName = createSelector(
  selectPagesStateState,
  state => state.caller_first_name
)
export const callerLastName = createSelector(
  selectPagesStateState,
  state => state.caller_last_name
)
export const caller_cia_nameName = createSelector(
  selectPagesStateState,
  state => state.caller_cia_name
)
export const callerEmail = createSelector(
  selectPagesStateState,
  state => state.caller_email
)

export const managerName = createSelector(
  selectPagesStateState,
  state => state.manager_name
)
export const managerEmail = createSelector(
  selectPagesStateState,
  state => state.manager_email
)

export const activeCalls = createSelector(
  selectPagesStateState,
  state => state.active_calls
)
export const gotReps = createSelector(
  selectPagesStateState,
  state => state.reps
)
export const gotUsers = createSelector(
  selectPagesStateState,
  state => state.users
)
export const ciaQR = createSelector(
  selectPagesStateState,
  state => state.cia_qr_img
)
export const repQR = createSelector(
  selectPagesStateState,
  state => state.rep_qr_img
)

export const customizeAccount = createSelector(
  selectPagesStateState,
  state => state.customize_account
)
export const showAsset = createSelector(
  selectPagesStateState,
  state => state.show_asset
)
export const showFolder = createSelector(
  selectPagesStateState,
  state => state.show_folder
)

export const iAmManager = createSelector(
  selectPagesStateState,
  state => state.i_am_manager
)
export const gotContracts = createSelector(
  selectPagesStateState,
  state => state.contracts
)
export const repLink = createSelector(
  selectPagesStateState,
  state => state.rep_link
)
export const recStatus = createSelector(
  selectPagesStateState,
  state => state.rec_status
)
export const debug = createSelector(
  selectPagesStateState,
  state => state.debug
)

export const headerPath = createSelector(
  selectPagesStateState,
  state => state.header_path
)
export const notifySnd = createSelector(
  selectPagesStateState,
  state => state.notify_snd
)
export const notifyUsr = createSelector(
  selectPagesStateState,
  state => state.notify_usr
)
export const contactInfo = createSelector(
  selectPagesStateState,
  state => state.contact_info
)
export const callerIsPhone = createSelector(
  selectPagesStateState,
  state => state.caller_is_phone
)
export const myRoll = createSelector(
  selectPagesStateState,
  state => state.my_roll
)

export const openMenu = createSelector(
  selectPagesStateState,
  state => state.open_menu
)
export const hasGoogleCalendar = createSelector(
  selectPagesStateState,
  state => state.has_google_calendar
)
export const hasAPI = createSelector(
  selectPagesStateState,
  state => state.has_api
)
export const onBoard = createSelector(
  selectPagesStateState,
  state => state.on_board
)

export const apiParams = createSelector(
  selectPagesStateState,
  state => state.api_params
)
export const showAPI = createSelector(
  selectPagesStateState,
  state => state.show_api
)

export const filterOptions = createSelector(
  selectPagesStateState,
  state => state.filter_options
)
export const filteredAssets = createSelector(
  selectPagesStateState,
  state => state.filtered_assets
)
export const unselectAllAssets = createSelector(
  selectPagesStateState,
  state => state.unselect_all
)
export const filteredFolders = createSelector(
  selectPagesStateState,
  state => state.filtered_folders
)
export const saveAssets = createSelector(
  selectPagesStateState,
  state => state.save_assets
)
export const inTab = createSelector(
  selectPagesStateState,
  state => state.tab
)
export const showWelcome = createSelector(
  selectPagesStateState,
  state => state.welcome
)
export const inPhone = createSelector(
  selectPagesStateState,
  state => state.in_phone
)
export const closeProgress = createSelector(
  selectPagesStateState,
  state => state.close_progress
)
export const apiUrlParams = createSelector(
  selectPagesStateState,
  state => state.api_url_params
)
export const playRec = createSelector(
  selectPagesStateState,
  state => state.play_rec
)
export const codeFromURL = createSelector(
  selectPagesStateState,
  state => state.code_from_url
)
// export const askCodeFrom = createSelector(
//   selectPagesStateState,
//   state => state.ask_code_from
// )

export const calendlyRep = createSelector(
  selectPagesStateState,
  state => state.calendly_rep
)

export const accCreatedAt = createSelector(
  selectPagesStateState,
  state => state.acc_created_at
)
export const showCC = createSelector(
  selectPagesStateState,
  state => state.show_cc
)
export const gotInvoices = createSelector(
  selectPagesStateState,
  state => state.invoices
)
export const askHangUp = createSelector(
  selectPagesStateState,
  state => state.ask_hangup
)
export const gotConverge = createSelector(
  selectPagesStateState,
  state => state.converge
)
export const gotBillInfo = createSelector(
  selectPagesStateState,
  state => state.bill_info
)
export const gotPlans = createSelector(
  selectPagesStateState,
  state => state.plans
)
export const convergeAcc = createSelector(
  selectPagesStateState,
  state => state.converge_acc
)

export const merchantId = createSelector(
  selectPagesStateState,
  state => state.merchant_id
)
export const gotCode = createSelector(
  selectPagesStateState,
  state => state.code
)
export const showToolAddAasset = createSelector(
  selectPagesStateState,
  state => state.show_tool_add_asset
)
export const accOverdue = createSelector(
  selectPagesStateState,
  state => state.acc_overdue
)
export const numActiveCalls = createSelector(
  selectPagesStateState,
  state => state.num_active_calls
)

export const onBoarding = createSelector(
  selectPagesStateState,
  state => state.on_boarding
)
export const onBoardURLs = createSelector(
  selectPagesStateState,
  state => state.on_board_urls
)
export const siteEnabled = createSelector(
  selectPagesStateState,
  state => state.site_enabled
)
export const siteReady = createSelector(
  selectPagesStateState,
  state => state.site_ready
)
export const isDealer = createSelector(
  selectPagesStateState,
  state => state.is_dealer
)
export const gotReseller = createSelector(
  selectPagesStateState,
  state => state.reseller
)
export const editingResellerAcc = createSelector(
  selectPagesStateState,
  state => state.editing_reseller_acc
)
export const appInSharedScrn = createSelector(
  selectPagesStateState,
  state => state.app_in_shared_scrn
)
