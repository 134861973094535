import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { PagesState } from '../pages/pages.model';
import { iAmManager } from '../pages/pages.selectors';
import { WSSState } from '../wss/wss.model';
import { callId, lastMsg, managerId, myId, repId } from '../wss/wss.selectors';
import { WSSService } from '../wss/wss.service';
import { GotStream, HasVideoCamera, MediaState, RemoveStream, SetAudio, SetShare, SetVideo, TranscriptLine } from './k-video.actions';
import { KVideoState } from './k-video.model';
import { audioDevId, audioOn, audioSpeakerId, mediaState, videoDevId, videoOn } from './k-video.selectors';
declare var webkitSpeechRecognition: any

@Injectable({
  providedIn: 'root'
})
export class KVideoService {
  bVideoOn: boolean = false;
  bAudioOn: boolean = false;
  selected_vdev_id: string | undefined
  selected_adev_id: string | undefined
  localStream: MediaStream | undefined
  shareStream: MediaStream | undefined

  my_id: string | undefined
  rep_id: string | undefined
  manager_id: string | undefined
  call_id: string | undefined
  role: string = "rep"
  bHasVideoCamera: boolean = false
  constructor(
    private wssState: Store<WSSState>,
    private kVideoState: Store<KVideoState>,
    private wss_service: WSSService,
    public pagesState: Store<PagesState>,
  ) {
    console.log("k-video service")

    let vdev = localStorage.getItem('vdev')
    if (vdev) {
      this.selected_vdev_id = vdev
    }
    let adev = localStorage.getItem('adev')
    if (adev) {
      this.selected_adev_id = adev
    }
    this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg) {
        if (msg.hasOwnProperty("share_screen")) {
          if (msg.share_screen != this.role) {
            this.stopShareScreen()
          }
        } else if (msg.hasOwnProperty("transcript_line")) {
          this.kVideoState.dispatch(new TranscriptLine(msg.from, msg.transcript_line));
        }
      }
    })
    this.kVideoState.select(audioDevId).subscribe((id: string | undefined) => {
      if (id) {
        console.log("audioDevId " + id)
        let adev = localStorage.getItem('adev')
        if (adev != id) {
          this.selected_adev_id = id
          this.localStreamChanged("adev_id")
          localStorage.setItem('adev', this.selected_adev_id)
        }
      }
    })

    this.kVideoState.select(videoDevId).subscribe((id: string | undefined) => {
      if (id) {
        console.log("videoDevId " + id)
        let vdev = localStorage.getItem('vdev')
        if (vdev != id) {
          this.selected_vdev_id = id
          this.localStreamChanged("vdev_id")
          localStorage.setItem('vdev', this.selected_vdev_id)
        }
      }
    })


    this.pagesState.select(iAmManager).subscribe((manager: boolean) => {
      if (manager) {
        this.role = "manager"
      } else {
        this.role = "rep" //we used agent in sing-tools.component
      }
    })


    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (call_id != this.call_id) {
        this.call_id = call_id
        if (this.call_id) {
          // this.startTranscritpion()
          this.setVideoStates(true)
        } else {
          this.stopShareScreen()

          this.kVideoState.dispatch(new RemoveStream("remote_share"))
          this.localStreamChanged("call_off")
        }
      }

      if (!call_id && this.call_id) {
        this.stopTranscritpion()
      }

    })
    this.wssState.select(managerId).subscribe((manager_id: string | undefined) => {
      if (manager_id) {
        this.setVideoStates(true)
      }
    })
    this.kVideoState.select(audioOn).subscribe(async (on: boolean) => {
      if (this.bAudioOn && !on && this.localStream) {
        let tracks = this.localStream.getAudioTracks()
        for (let i = 0; i < tracks.length; i++) {
          let t = tracks[i]
          t.stop()
          // this.localStream.removeTrack(t)
        }
      }
      let bChanged = (this.bAudioOn != on)
      if (bChanged) {
        this.bAudioOn = on
        await this.localStreamChanged("audio")
        this.setVideoStates(true)
      }
    })
    this.kVideoState.select(videoOn).subscribe(async (on: boolean) => {
      if (this.bVideoOn && !on && this.localStream) {
        let tracks = this.localStream.getVideoTracks()
        tracks.forEach((t) => {
          t.stop()
        })
      }
      let bChanged = (this.bVideoOn != on)
      if (bChanged) {
        this.bVideoOn = on
        if (!this.bHasVideoCamera) {
          this.bVideoOn = false
        }
        await this.localStreamChanged("video")
        this.setVideoStates(true)
      }
    })

    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
      //Turn on the video when you login
      if (this.my_id) {
        setTimeout(async () => {
          let devices = await navigator.mediaDevices.enumerateDevices()
          for (let i = 0; i < devices.length; i++) {
            let device = devices[i]
            if (device.kind == "videoinput") {
              this.bHasVideoCamera = true
            }
          }
          this.kVideoState.dispatch(new HasVideoCamera(this.bHasVideoCamera))
          this.bVideoOn = this.bHasVideoCamera; //To avoid debouncing
          this.bAudioOn = true;
          this.kVideoState.dispatch(new SetAudio(true))
          if (this.bHasVideoCamera) {
            this.kVideoState.dispatch(new SetVideo(true))
          }
          this.localStreamChanged("start")
          this.setVideoStates(true)
        })
      }

    })
    this.wssState.select(repId).subscribe((rep_id: string | undefined) => {
      this.rep_id = rep_id
      this.setRepOrManagerStream()
    })
    this.wssState.select(managerId).subscribe((manager_id: string | undefined) => {
      this.manager_id = manager_id
      this.setRepOrManagerStream()
    })

  }
  setRepOrManagerStream() {
    if (this.rep_id) {
      if (this.my_id == this.rep_id && this.localStream) {
        this.kVideoState.dispatch(new GotStream("rep", this.localStream))
      }
    } else {
      if (this.manager_id) {
        this.kVideoState.dispatch(new RemoveStream("rep"))
      }
    }
    if (this.manager_id) {
      if (this.my_id == this.manager_id && this.localStream) {
        this.kVideoState.dispatch(new GotStream("manager", this.localStream))
      }
    } else {
      if (this.rep_id) {
        this.kVideoState.dispatch(new RemoveStream("manager"))
      }
    }
  }


  async stopShareScreen() {
    if (this.shareStream) {
      console.log("share STOP RemoveStream(local_share)")
      this.shareStream.getTracks().forEach(track => track.stop())
      delete this.shareStream
    }
    this.kVideoState.dispatch(new RemoveStream("local_share"))
    this.kVideoState.dispatch(new RemoveStream("remote_share"))
    this.kVideoState.dispatch(new SetShare(false))
    this.wss_service.sendMessageToOtherMembers(({ kvideo: "remote_share" }))
  }
  async shareScreen(bIsFrame?: boolean) {
    let jmsg = { //when this is received after a call it starts the offer
      share_screen: this.role,
    }
    this.wss_service.sendMessageToOtherMembers((jmsg))

    const mediaDevices = navigator.mediaDevices as any; //Fix to Property 'getDisplayMedia' does not exist on type 'MediaDevices
    try {
      let constrains: any = { video: true }
      if (bIsFrame) {
        constrains = { preferCurrentTab: true }
      }
      this.shareStream = await mediaDevices.getDisplayMedia(constrains);
      if (this.shareStream) {
        //Feb 19, 24
        let jmsg: any = {
          media_state: this.role,
          video: this.bVideoOn,
          audio: this.bAudioOn
        }
        if (this.shareStream) {
          jmsg.sharing = true
        } else {
          jmsg.sharing = false
        }
        this.wss_service.sendMessageToOtherMembers((jmsg))

        console.log("share START GotStream(local_share)")
        this.kVideoState.dispatch(new GotStream("local_share", this.shareStream))
        this.kVideoState.dispatch(new SetShare(true))
        let vt = this.shareStream.getVideoTracks()
        let me = this
        vt[0].onended = function ended() {
          console.log("Stream media inactive stop it");
          me.stopShareScreen()
        }
      }
    } catch (e: any) {
      this.kVideoState.dispatch(new SetShare(false))
      console.error("k-video error sharing " + e)
    }
  }
  //****************************************************************************
  //Keep getLocalStream in sync with site Nov 15 7:31AM
  //****************************************************************************
  bGettingLocalStream: boolean = false
  async localStreamChanged(what_changed: string) {
    return new Promise<void>(async (resolve, reject) => {
      if (this.bGettingLocalStream) {
        console.log("#LS Bussy with get Local streams video = " + this.bVideoOn + " audio " + this.bAudioOn)
        //This is a race condition, when the user changes audio and video before we finish getting the local stream
        resolve()
        return; //We can ignore it by the time we get back from getLocalSteam
        //the bAudioOn and bVideoOn flags should be what we need
      }
      this.bGettingLocalStream = true;
      console.log("#LS get Local streams video = " + this.bVideoOn + " audio " + this.bAudioOn)
      try {
        if (this.localStream && !this.bVideoOn && !this.bAudioOn) {
          let tracks = this.localStream.getTracks()
          tracks.forEach((t) => {
            t.stop()
          })
          delete this.localStream
          this.bGettingLocalStream = false
          resolve()
          return
        }
        if (what_changed == "audio"
          || what_changed == "video"
          || what_changed == "vdev_id"
          || what_changed == "adev_id"
          || this.bVideoOn
          || this.bAudioOn
          || !this.localStream) {
          let stream = await this.getLocalStream()
          if (this.localStream && (what_changed == "audio" || what_changed == "adev_id")) {
            if (this.bAudioOn) {
              let n_ats = stream.getAudioTracks()
              let o_ats = this.localStream.getAudioTracks()
              if (what_changed == "adev_id") { //July 26, 2024
                this.localStream.removeTrack(o_ats[0])
                o_ats = this.localStream.getAudioTracks()
              }
              if (o_ats.length > 0) {
                console.error("the old local stream allready has audio")
              } else if (n_ats.length == 0) {
                console.error("the new local stream has no audio")
              } else {
                this.localStream.addTrack(n_ats[0])
              }
            } else {
              let n_ats = stream.getAudioTracks()
              let o_ats = this.localStream.getAudioTracks()
              if (n_ats.length > 0) {
                console.error("the new local stream  has audio")
              } else if (o_ats.length == 0) {
                console.error("the old local stream has no audio")
              } else {
                this.localStream.removeTrack(o_ats[0])
              }

            }
          } else {
            this.localStream = stream
          }

          this.setVideoStates(false)
          if (this.localStream) {
            this.kVideoState.dispatch(new GotStream("me", this.localStream))
            this.setRepOrManagerStream()
          }
        }
      } catch (e: any) {
        console.error(e)
        reject(e)
      }
      this.bGettingLocalStream = false
      resolve()
    })
  }

  setVideoStates(force_send: boolean) {
    console.log("#LS setVideoStates = " + this.bVideoOn + " audio " + this.bAudioOn)
    let prev_video: boolean = false
    let new_video: boolean = false
    let prev_audio: boolean = false
    let new_audio: boolean = false
    if (this.localStream && this.localStream.active) {
      let tracks = this.localStream.getTracks()
      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i]
        if (track.kind == 'video') {
          prev_video = track.enabled
          track.enabled = this.bVideoOn
          new_video = track.enabled
        } else if (track.kind == 'audio') {
          prev_audio = track.enabled
          track.enabled = this.bAudioOn
          new_audio = this.bAudioOn
        }
      }
    }
    if (force_send || prev_video != new_video || prev_audio != new_audio) {
      if (!this.call_id) {
        let jmsg = { //keeps the socket up to date
          caller_video: this.bVideoOn,
          caller_audio: this.bAudioOn
        }
        this.wss_service.sendMessage(jmsg)
      } else {
        let jmsg = { //when this is received after a call it starts the offer
          media_state: this.role,
          video: this.bVideoOn,
          audio: this.bAudioOn
        }
        this.wss_service.sendMessageToOtherMembers((jmsg))
      }
    }
    let jmsg = { //when this is received after a call it starts the offer
      media_state: this.role,
      video: this.bVideoOn,
      audio: this.bAudioOn
    }
    console.log("#CASU " + JSON.stringify(jmsg))
    this.kVideoState.dispatch(new MediaState(jmsg))  //Dec 13, this sett the bNoSound in the caller

  }

  async getLocalStream() {
    return new Promise<MediaStream>(async (resolve, reject) => {

      //Check to make sure that we dont allready have them if we do and they are muted, enable them


      let constrains: any = { 'video': this.bVideoOn, 'audio': this.bAudioOn, echoCancellation: true }
      if (this.bVideoOn && this.selected_vdev_id) {
        constrains.video = { deviceId: { exact: this.selected_vdev_id } };
      }
      if (this.bAudioOn && this.selected_adev_id) {
        constrains.audio = { deviceId: { exact: this.selected_adev_id } };
      }
      if (this.bAudioOn) {
        constrains.noiseSuppression = true
      }
      try {
        console.log("#LS  got local stream " + JSON.stringify(constrains))
        if (this.localStream) {
          let tracks = this.localStream.getTracks()
          tracks.forEach((t: any) => {
            if (!this.bAudioOn && t.kind == "audio") {
              t.stop()
            }
            if (!this.bVideoOn && t.kind == "video") {
              t.stop()
            }
          })
        }
        let stream = await navigator.mediaDevices.getUserMedia(constrains);
        let tracks = stream.getTracks()
        tracks.forEach((track: MediaStreamTrack) => {
          console.log("     #LS " + track.kind + "  " + track.enabled + "  " + track.id)
        })

        resolve(stream)
      } catch (e: any) {

        if (e.name == "OverconstrainedError") {
          if (e.constraint == "deviceId") {
            let vdev = localStorage.getItem('vdev')
            if (vdev) {
              delete this.selected_vdev_id
              localStorage.removeItem('vdev')

            }
            let adev = localStorage.getItem('adev')
            if (adev) {
              delete this.selected_adev_id
              localStorage.removeItem('adev')
            }

            if (vdev || adev) {
              let stream = this.getLocalStream()
              if (stream) {
                resolve(stream)
                return
              }
            }
          }
        }
        reject("Error: get user media " + e)
      }
    })
  }
  //****************************************************************************
  //****************************************************************************
  recognition: any
  recognizing: boolean = false
  detected_sound_at: number = 0
  start_line_time: number = 0
  detectedSound(roll: string) {
    if (roll == "rep") {
      this.detected_sound_at = new Date().getTime()
    }
  }
  startTranscritpion() {
    let me = this
    let ignore_onend: boolean = false;

    if (this.recognition) {
      console.log("Allready running")
    } else {

      var start_timestamp: number;



      if (!('webkitSpeechRecognition' in window)) {
        console.log("Please upgrade");
      } else {
        console.log("starting webkitSpeechRecognition");
        this.recognition = new webkitSpeechRecognition();
        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onstart = function() {

          console.log('info_speak_now');
          me.recognizing = true
        };

        this.recognition.onerror = function(event: any) {

          if (event.error == 'no-speech') {
            console.error('info_no_speech');
            ignore_onend = true;
            return
          }
          if (event.error == 'audio-capture') {
            console.error('info_no_microphone');
            ignore_onend = true;
          }
          if (event.error == 'not-allowed') {
            if (event.timeStamp - start_timestamp < 100) {
              console.log('info_blocked');
            } else {
              console.log('info_denied');
            }
            ignore_onend = true;
          }
          if (me.recognizing) {
            me.recognition.stop();
            me.recognition.start();
          }
        };

        this.recognition.onend = function() {



          if (me.recognizing) {
            me.recognition.start();
            return;
          }

        };

        this.recognition.onresult = function(event: any) {
          if (!me.start_line_time) {
            me.start_line_time = new Date().getTime()
          }
          var interim_transcript = '';
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
              if (me.detected_sound_at > me.start_line_time) {
                let final_transcript = event.results[i][0].transcript;
                me.wss_service.sendMessageToOtherMembers(({ transcript_line: final_transcript, from: me.role }))
                me.kVideoState.dispatch(new TranscriptLine(me.role, final_transcript));
              }
              me.start_line_time = 0
              me.detected_sound_at = 0;
            }
          }
        };
      }
    }

    let lng = navigator.language;
    if (!lng) {
      lng = 'en-US'
    }
    this.recognition.lang = lng;
    this.recognition.start();
    ignore_onend = false;

  }
  stopTranscritpion() {
    this.recognizing = false
    if (this.recognition) {
      this.recognition.stop();
      return;
    }
  }

}
