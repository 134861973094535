import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ClearCanvasState, KZCode, SelectToolOpts, SetCanvasOpts } from '../canvas/canvas.actions';
import { ChatId, NewMsg } from '../chat/chat.actions';
import { ChatState } from '../chat/chat.model';
import { ControlsState } from '../controls/controls.model';
import { CheckVideo, ClearMediaState, MediaState } from '../k-video/k-video.actions';
import { KVideoState } from '../k-video/k-video.model';
import { CallerData, CallerIsPhone, CallWaiting, CleanOnHangUp, GotQueue, InPhone, NotifyError, RejectedCall, SetManagerEmail, SetManagerName, SetRepEmail, SetRepName, ShowActiveCalls, ShowAsset, SiteReady } from '../pages/pages.actions';
import { PagesState } from '../pages/pages.model';
import { callRejected, notifySnd } from '../pages/pages.selectors';
import { getAssetFromURL, getQueryParameter, loadScript } from '../utils';

import { GotMessage, HangUp, InCall, LeftCall, RemoteData, SessionExpired } from './wss.actions';
import { WSSState } from './wss.model';
import { busy, callerId, callId, customerId, myId, repId, sessionExpired, sessionId } from './wss.selectors';


@Injectable({
  providedIn: 'root'
})
export class WSSService {

  session_id: string | undefined
  customer_id: string | undefined
  my_id: string | undefined

  bBusy: boolean = false
  rep_id: string | undefined
  caller_id: string | undefined
  manager_id: string | undefined


  call_id: string | undefined

  intervalId: any
  socket: any;
  bConnected: boolean = false;
  wss_counter: number = 0 //reset by answer


  calls_rejected: any = {} //used to keep the call notification wnd open in the first incoming caller
  //unless that caller is no longer in the queue

  last_queue: any[] = []
  last_in_queue_time: number = 0 //if there is any new callers from the last time
  //we got a queue and process it we need to play the sound
  notify_snd: boolean = true
  bSessioExpired: boolean = false


  constructor(
    private httpClient: HttpClient,
    private wssState: Store<WSSState>,
    private pagesState: Store<PagesState>,
    public controlsState: Store<ControlsState>,
    private kVideoState: Store<KVideoState>,
    private chatState: Store<ChatState>,
    public canvasStore: Store<CanvasState>,
  ) {
    console.log("wss ")
    this.wssState.select(sessionExpired).subscribe((at: number) => {
      if (at > 0) {
        this.bSessioExpired = true
      }
    })

    this.pagesState.select(notifySnd).subscribe((on: boolean) => {
      this.notify_snd = on
      this.connectWSS()
    })

    this.pagesState.select(callRejected).subscribe((call: any) => {
      if (call) {
        this.calls_rejected[call.start_calling_at] = call
        this.processQueueMsg()
      }
    })

    this.wssState.select(sessionId).subscribe((session_id: string | undefined) => {
      this.session_id = session_id
      this.connectWSS()
    })
    this.wssState.select(repId).subscribe((rep_id: string | undefined) => {
      this.rep_id = rep_id
    })
    this.wssState.select(callerId).subscribe((caller_id: string | undefined) => {
      this.caller_id = caller_id
    })
    this.wssState.select(customerId).subscribe((customer_id: string | undefined) => {
      if (!this.customer_id && customer_id) {
        this.customer_id = customer_id
        this.connectWSS()
        setTimeout(() => {
          this.loadKzM()
        })

      }

    })
    this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
      this.connectWSS()
    })
    this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (this.call_id != call_id) {
        this.call_id = call_id
        if (!this.call_id) {
          this.pagesState.dispatch(new CleanOnHangUp())
        }
      }
    })

    this.wssState.select(busy).subscribe((busy: boolean) => {
      if (this.bBusy != busy) {
        this.bBusy = busy
        this.sendMessage({ busy: busy })
      }
    })
    //See if we need to koad kzLib
    let mywnd = window.self
    let iframe = mywnd.frameElement as HTMLIFrameElement;
    let urlParams
    if (iframe) {
      const urlObj = new URL(iframe.src);
      console.log("kzm init kzmLoader src " + iframe.src);
      urlParams = new URLSearchParams(urlObj.search);

    } else {
      let queryString = window.location.search;
      console.log("kzm init kzmLoader " + queryString);
      urlParams = new URLSearchParams(queryString);
    }

    let kzCode = urlParams.get('kzCode');
    if (kzCode) { //This is only in the iFrame
      console.log("Load kzLib")
      let params: any = {}
      urlParams.forEach((value: any, key: string) => {
        params[key] = value
      });
      params["kid"] = urlParams.get("id")
      params["id"] = "kzLib"
      setTimeout(() => {
        loadScript("assets/api/p2p/kzLib.js", params)
      })
    }
  }

  hangUp() {
    this.sendMessage({ hang_up: true })
    this.wssState.dispatch(new InCall(undefined))
    this.kVideoState.dispatch(new ClearMediaState("caller"))
    this.canvasStore.dispatch(new ClearCanvasState())
  }

  logOut() {
    if (this.socket) {
      delete this.session_id;
      this.socket.close()
    }
  }

  async connectWSS() {
    if (!this.customer_id || !this.my_id || !this.session_id) {
      return;
    }

    if (!this.bConnected) {
      try {
        this.reconnectWSS()
      } catch (e: any) {
      }
    }
    if (!this.intervalId) {
      this.intervalId = setInterval(async () => {
        if (!this.session_id) {
          clearInterval(this.intervalId)
          return;
        }
        if (!this.bConnected) {
          try {
            this.reconnectWSS()
          } catch (e: any) {
          }
        } else {
          if (++this.wss_counter > 14) {
            this.wss_counter = 0;
            let wss_ping: any = {
              wss_ping: new Date().getTime(),
            }
            // if (!this.call_id) {
            wss_ping.token = this.session_id
            // }
            if (!this.bSessioExpired) {
              this.sendMessageToOtherMembers(wss_ping)
            }
          }
        }
      }, 3000)
    }
    // this.getLocalStream()
  }
  reconect_attempt: number = 1;
  bReConnecting: boolean = false;
  public wss_url: string = ""
  reconnectWSS() {
    let me = this
    console.log("Reconnect WSS ")
    if (this.bReConnecting) {
      return;
    }
    this.bReConnecting = true;
    try {
      this.wss_url = "wss://" + location.host
      if (location.hostname.indexOf("localhost") >= 0
        || location.hostname.indexOf("local.callvendi") >= 0) {
        let host = localStorage.getItem('host')//Recoded to be used with localhost
        if (!host) {
          host = location.hostname
        }
        if (host) {
          if (host.startsWith("local")) {
            this.wss_url = "ws://" + host
          } else {
            this.wss_url = "wss://" + host
          }
        }
      }

      let me = this;
      this.reconect_attempt++;
      try {
        this.socket = new WebSocket(this.wss_url);
      } catch (e: any) {
        console.error("Could not get the scket for " + this.wss_url + e)
      }
      this.socket.onmessage = function(msg: any) {
        // console.log("<--(ws) " + msg.data.substring(0, 150))
        me.receiveMessage(msg.data)
      }

      this.socket.onopen = function() {
        console.log("WSS opened")
        //  me.toolsStore.dispatch(new SetWSSState("ready"))
        me.bConnected = true;
        me.bReConnecting = false;
        me.login()
      }
      this.socket.onclose = function() {
        console.log("Socket closed")
        me.bConnected = false;
        me.bReConnecting = false;
      }

      this.socket.onerror = function(e: any) {
        console.log("Socket error " + JSON.stringify(e))
        me.bConnected = false;
        me.bReConnecting = false;
      }
    } catch (e: any) {
      console.log("Web socket error " + e)
      this.bReConnecting = false;
    }
  }
  login() {
    let msg = {
      connect: "rep",
      session_id: this.session_id,
      customer_id: this.customer_id,
      my_id: this.my_id,
      call_id: this.call_id,
      busy: this.bBusy
    }
    this.sendMessage(msg)
  }

  //****************************************************************************
  //  Receive Message
  //****************************************************************************
  receiveMessage(smsg: any) {

    let jmsg = JSON.parse(smsg)
    // if (!jmsg.hasOwnProperty("wss_ping") && !jmsg.hasOwnProperty("webrtc")) {
    //   console.log("wss: <-- " + new Date().toString() + " " + smsg)
    // }
    if (jmsg.hasOwnProperty("error")) {
      console.error(smsg)
      return
    }

    if (jmsg.hasOwnProperty("queue")) {
      this.last_queue = jmsg.queue
      this.processQueueMsg()
    }
    if (jmsg.hasOwnProperty("active")) {
      // if (jmsg.active) {
      this.pagesState.dispatch(new ShowActiveCalls(jmsg.active))
      // }
    }
    if (jmsg.hasOwnProperty("in_call")) {
      if (this.my_id) {
        if (this.my_id != jmsg.in_call.rep_id) {
          console.error("Check rep_id")
        }
      } else {
        console.error("Check my_id")
      }
      this.pagesState.dispatch(new CallWaiting(undefined))
      this.wssState.dispatch(new InCall(jmsg.in_call))

      if (jmsg.in_call.chat_id) {
        this.chatState.dispatch(new ChatId(jmsg.in_call.chat_id))
      }
      if (jmsg.in_call.caller_first_name) {
        this.pagesState.dispatch(new CallerData(jmsg.in_call))
      }
      // if (jmsg.in_call.caller_email) {
      //   this.pagesState.dispatch(new CallerEmail(jmsg.in_call.caller_email))
      // }

      return
    }
    // if (jmsg.chat_msg) { //this is picked up by the chat.server
    //   this.chatState.dispatch(new NewMsg(jmsg))
    //   return
    // }

    if (jmsg.hasOwnProperty("add_manager")) {
      this.wssState.dispatch(new InCall(jmsg.add_manager))
      if (jmsg.add_manager.manager_name) {
        this.pagesState.dispatch(new SetManagerName(jmsg.add_manager.manager_name))
      }
      if (jmsg.add_manager.manager_email) {
        this.pagesState.dispatch(new SetManagerEmail(jmsg.add_manager.manager_email))
      }
      if (jmsg.add_manager.rep_name) {
        this.pagesState.dispatch(new SetRepName(jmsg.add_manager.rep_name))
      }
      if (jmsg.add_manager.rep_email) {
        this.pagesState.dispatch(new SetRepEmail(jmsg.add_manager.rep_email))
      }
      // if (jmsg.add_manager.caller_name) {
      //   this.pagesState.dispatch(new CallerName(jmsg.add_manager.caller_name))
      // }
      // if (jmsg.add_manager.caller_email) {
      //   this.pagesState.dispatch(new CallerEmail(jmsg.add_manager.caller_email))
      // }
      return;
    }
    if (jmsg.hasOwnProperty("left_call")) {
      this.wssState.dispatch(new LeftCall(jmsg.left_call))
      this.kVideoState.dispatch(new ClearMediaState(jmsg.left_call))
      this.kVideoState.dispatch(new CheckVideo(jmsg.kvideo))
      return
    }
    //sent by k-video service on stopShareScreen
    if (jmsg.hasOwnProperty("kvideo")) {
      this.kVideoState.dispatch(new CheckVideo(jmsg.kvideo))
      return
    }

    if (jmsg.hasOwnProperty("media_state")) {
      this.kVideoState.dispatch(new MediaState(jmsg))
      return
    }
    if (jmsg.in_phone) {
      this.pagesState.dispatch(new InPhone(jmsg.in_phone))
      return
    }
    if (jmsg.torep) {
      if (jmsg.torep.hasOwnProperty("open_remote")) {
        if (jmsg.torep.open_remote) {
          this.openRemote(jmsg)
          return
        }
        this.pagesState.dispatch(new NotifyError("The caller did not authorize you to open"))

      }
    }
    if (jmsg.session_expired) {
      this.wssState.dispatch(new SessionExpired())
    }
    if (jmsg.asset_url) {
      try {
        if (jmsg.asset_url && typeof jmsg.asset_url === 'string') {
          let url1 = atob(jmsg.asset_url)
          let url2 = window.decodeURI(url1)
          let url3 = window.unescape(url2)
          let asset = getAssetFromURL(url3)
          console.log(jmsg.asset_url)
          if (asset) {
            this.pagesState.dispatch(new ShowAsset(asset))
            this.sendMessageToOtherMembers({ show_asset: asset })
          }
        }
      } catch (e) {
        console.error(e)
      }
      return
    }
    if (jmsg.k_site) {
      this.pagesState.dispatch(new SiteReady())
    }
    if (jmsg.fromKzApi) {
      console.log(JSON.stringify(jmsg))
    }
    this.wssState.dispatch(new GotMessage(jmsg))
  }


  sendMessageToMembersAndMe(jmsg: any) {
    this.sendMessage(jmsg, true)
    this.wssState.dispatch(new GotMessage(jmsg))
  }
  sendMessageToOtherMembers(jmsg: any) {
    this.sendMessage(jmsg, true)
  }
  processQueueMsg() {
    //First filter the rejecgted calls and remove entries from the rejected queue that are no longet in the queue
    let last_in_queue_time = this.last_in_queue_time
    this.last_in_queue_time = new Date().getTime()

    let queue: any[] = []
    let new_rejected_calls: any = {}
    let bThereIsACall = false
    for (let i = 0; i < this.last_queue.length; i++) {
      let entry = this.last_queue[i]
      if (this.calls_rejected[entry.start_calling_at]) { //if it has been rejected
        new_rejected_calls[entry.start_calling_at] = entry //keep it
      } else {
        queue.push(entry) //not jecteted stays in the queue
        if (entry.state == "calling") {
          bThereIsACall = true;
        }
      }
    }
    this.calls_rejected = new_rejected_calls
    this.pagesState.dispatch(new GotQueue(queue))
    if (!bThereIsACall) {
      this.pagesState.dispatch(new RejectedCall(undefined))
      this.canvasStore.dispatch(new SelectToolOpts(""))
      return
    }

    /*
    Rejected calls do NOT show up in the queue (red button)
    Company link rings by everybody
    company link with a previous rep_id, it is delayed 10 seconds for the other reps
    If it's a rep link, only answer by the rep
    */
    let call_waiting: any | undefined
    if (!this.call_id) {
      //Find the calls for this rep
      for (let i = 0; i < queue.length; i++) {
        let entry = queue[i]
        if (entry.state == "calling") {
          if (entry.rep_id) {
            if (entry.rep_id == this.my_id) {
              //The call has not been rejected
              call_waiting = entry
              break
            }
          } else if (entry.prev_call) {
            if (entry.prev_call.rep_id == this.my_id) {
              //The call has not been rejected
              call_waiting = entry
              break
            }
          }
        }
      }
      //now find the ones for the company
      let now = new Date().getTime()
      for (let i = 0; i < queue.length; i++) {
        let entry = queue[i]
        if (entry.state == "calling" && !entry.rep_id) { //is not for a specify rep
          if (!entry.prev_call) {
            call_waiting = entry
            break
          } else {
            let dif = now - entry.start_calling_at
            if (dif > 20000) { //Delay 20 seconds
              call_waiting = entry
              break
            }
          }
        }
      }

      if (call_waiting) {
        this.canvasStore.dispatch(new SelectToolOpts("open-queue-wnd")) //We want to open it from the control, to get the right left position
        this.pagesState.dispatch(new CallWaiting(call_waiting))
      }
      return;
    }
    //we are in a call, we dont sent the call waiting, but if there is a new call we need to play the sound

    for (let i = 0; i < queue.length; i++) {
      let entry = queue[i]
      if (entry.state == "calling") {
        if (entry.start_calling_at > last_in_queue_time && this.notify_snd) {
          console.log("Play sound")
          let audio = new Audio();
          try {
            audio.src = "assets/notify.mp3";
            audio.load();
            audio.play()
          } catch (e) {
            console.error(e)
          }
          break; //only play once
        }
      }
    }
    return
  }
  public sendMessage(jmsg1: any, forward?: boolean) {
    if (this.bConnected == false) {
      if (this.bReConnecting) {
        console.log("Message not send, reconecting " + JSON.stringify(jmsg1))
        return
      }
      console.error("Message not send, recnot connected onecting " + JSON.stringify(jmsg1))
      return
    }
    if (!this.socket) {
      console.error("Error sendMessage no socket " + JSON.stringify(jmsg1))
      return;
    }
    let jmsg = Object.assign({}, jmsg1)
    jmsg.customer_id = this.customer_id;
    jmsg.my_id = this.my_id;
    jmsg.call_id = this.call_id
    if (forward) {
      jmsg.forward = true
    }
    try {
      let smsg = JSON.stringify(jmsg)
      // if (!jmsg.hasOwnProperty("wss_ping") && !jmsg.hasOwnProperty("webrtc")) {
      //   console.log("wss:--> " + smsg)
      // }
      this.socket.send(smsg)
    } catch (e: any) {
      console.log(e + " " + JSON.stringify(jmsg1))
      if (this.socket.readyState != WebSocket.CLOSED
        || this.socket.readyState != WebSocket.CLOSING) {
        this.socket.close()
      }
    }
  }
  async answer(queue_entry: any) {
    let msg = {
      answer: queue_entry.caller_id,
      token: this.session_id
    }
    if (queue_entry.caller_name) {
      this.pagesState.dispatch(new CallerData(queue_entry))
    }
    // if (queue_entry.caller_email) {
    //   this.pagesState.dispatch(new CallerEmail(queue_entry.caller_email))
    // }
    if (queue_entry.remote_origin) {
      let data: any = { remote_origin: queue_entry.remote_origin }
      if (queue_entry.remote_session_id) {
        data.remote_session_id = queue_entry.remote_session_id
      }
      if (queue_entry.remote_path) {
        data.remote_path = queue_entry.remote_path
      }
      this.wssState.dispatch(new RemoteData(data))
    }




    this.pagesState.dispatch(new CallerIsPhone(queue_entry.is_phone))

    this.sendMessageToMembersAndMe(msg)
    if (queue_entry.contract_id) {
      console.log("open the contract")
      try {
        let rv: any = await this.httpClient.get('/r_contract/' + queue_entry.contract_id + '?email=' + queue_entry.caller_email).toPromise();
        this.pagesState.dispatch(new ShowAsset(rv))
        this.canvasStore.dispatch(new SetCanvasOpts(
          {
            bDraw: false,
            bDownload: false,
            bContract: true
          }
        ))
      } catch (e: any) {
        console.error("Error getting contract " + e)

      }
    }
  }
  async getKzCode() {
    return new Promise<any>(async (resolve, reject) => {

      try {
        let rv: any = await this.httpClient.get('/kzCode').toPromise();
        resolve(rv.kzCode)
      } catch (e: any) {
        reject(e)
      }
    })
  }
  async  setkzCode(in_url: string) {
    return new Promise<string>(async (resolve, reject) => {

      try {
        let kzCode = await this.getKzCode()
        this.canvasStore.dispatch(new KZCode(kzCode))
        let url = new URL(in_url);
        url.searchParams.set('kzCode', kzCode);

        // this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(url.href);
        let wss_url = "wss://" + window.location.host
        if (location.hostname.indexOf("localhost") >= 0) {
          let host = localStorage.getItem('host')//Recoded to be used with localhost
          if (host) {
            if (host.startsWith("local")) {
              wss_url = "ws://" + host
            } else {
              wss_url = "wss://" + host
            }
          }
        }
        url.searchParams.set('wss_url', wss_url);
        console.log("wss_url " + wss_url + " " + url.href)

        url.searchParams.set('id', "rep");

        // this.sanitized_url = this.sanitizer.bypassSecurityTrustResourceUrl(url.href);

        resolve(url.href)
      } catch (e) {
        reject(e)
      }

    })
  }

  openRemote(jmsg: any) { //called by the parent to open the iframe
    if (this.session_id) {

      const urlObj = new URL(jmsg.torep.data.remote_origin);
      urlObj.searchParams.set('rsid', this.session_id);
      if (this.caller_id) {
        urlObj.searchParams.set('caller_id', this.caller_id)
      }

      let keys = Object.keys(jmsg.torep.data)
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let val = jmsg.torep.data[key]
        urlObj.searchParams.set(key, val)
      }
      // if (jmsg.data.remote_session_id) {
      //   urlObj.searchParams.set('session_id', jmsg.data.remote_session_id);
      // }
      urlObj.searchParams.set('wss_url', this.wss_url);

      let url = urlObj.toString();
      console.log("kzm remote open" + url)
      let asset: any = {
        type: "site",
        "url": url,
        asset_id: new Date().getTime(),
        bAdmin: false,

        // kzLib: true
      }
      if (jmsg.torep) {
        asset.app_in_caller = jmsg.torep.app_in_caller
      }
      this.pagesState.dispatch(new ShowAsset(asset)) //This will close the tools, but they are not closed because we are not showintg it, I will close it when the screen is shared

      return
    }
  }
  loadKzM() { //This will load the script in the child
    let params: any = {
      id: "kz",
      no_btn: "true",
    }
    let script_customer_id
    let queryString = window.location.search;
    console.log("kzm remote load script " + queryString)

    if (window.location.host.indexOf("keyzii.com") >= 0) { //test
      params.customer_id = "624dd98826ad13509e9d1412"
      script_customer_id = params.customer_id
    } else if (window.location.host.indexOf("callvendi.com") >= 0) { //test
      params.customer_id = "623de9b42540e1787ab059b1"
      script_customer_id = params.customer_id
      let use_sf = getQueryParameter("sf")
      if (use_sf) {
        params.customer_id = "604cfa8e196fdb0888e68102"
        script_customer_id = params.customer_id
      }
    } else if (window.location.host.indexOf("localhost") >= 0) { //dev
      // params.rep_id = "6692df35c53600249985507f"
      params.customer_id = "6692df35c53600249985507e"
      script_customer_id = "6692df35c53600249985507e"
    }

    if (script_customer_id != this.customer_id) {
      console.log("Load kzmLoader")
      loadScript("assets/api/site/kzmLoader.js", params)
    } else {
      // loadScript("assets/api/p2p/kzLib.js")//This is when keyzii is running a venditio user
    }
  }
}
